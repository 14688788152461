import React from "react"

import styled from "styled-components"

import MainContainer from "../../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Hero = styled(Jumbotron)`
  margin-bottom: 0px;
  border-radius: 0px;
  text-align: center;
`

const HeroSection = () => {

  return (
    <>
      <Hero>
        <MainContainer>
          <Container>
            <Row className="text-left align-items-center">
              <Col xs="12">
                <h5 className="text-left mb-3">High interest rate notes diversify your portfolio</h5>
                <p>
                Debt investments in private companies – usually available only to financial
                institutions – add diversification to your portfolio, and pay much higher
                interest rates than those paid by banks, money market funds, and
                government bonds.
                </p>
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </Hero>
    </>
  )
}

export default HeroSection
