import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "../atoms/link"

import styled from "styled-components"
import { primaryColor } from "../../colors"

import MainContainer from "../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"
import Button from "../atoms/button"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Hero = styled(Jumbotron)`
  margin-bottom: 0px;
  border-radius: 0px;
  padding-top: 1em;
  padding-bottom: 1em;

  background-color: #ffffff;
`

const GraphTextUp = styled.p`
  font-size: 1.3em;
  color: ${primaryColor};

  @media (max-width: 991.98px) {
    font-size: 1.0em;
    margin-bottom: 0.4em;
  }
`

const GraphTextCenter = styled.p`
  font-size: 3.8em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  span {
    background-color: ${primaryColor};
    color: #f5f5f5;
    padding: 0.2em 0.6em;
    border-radius: 0.1em;
  }

  @media (max-width: 991.98px) {
    font-size: 1.9em;
  }
`

const GraphTextDown = styled.p`
  font-size: 1.3em;
  margin-bottom: 0.2em;
  color: ${primaryColor};

  @media (max-width: 991.98px) {
    font-size: 1.0em;
    margin-top: 0.4em;
  }
`

const GraphTextDisclaimer = styled.p`
  font-size: 0.8em;
  margin-top: 0.2em;
  color: ${primaryColor};

  @media (max-width: 991.98px) {
    font-size: 0.7em;
    margin-top: 0.4em;
  }
`

function makeLastSpaceNonWrappable(text) {
  const trimmedText = text.trim();
  return trimmedText.replace(/(\b \b)(?!.*\1)/g, "&nbsp;")
}

const HeroIndex = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero_banner.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      isometricImg: file(relativePath: { eq: "hero_banner_isometric.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spreadsheet {
        moneyBoxTitle
        moneySaved
        elapsedTime
        elapsedTimeDisclaimer
      }
    }
  `)

  const moneyBoxTitle = makeLastSpaceNonWrappable(data.spreadsheet.moneyBoxTitle)
  const elapsedTime = makeLastSpaceNonWrappable(data.spreadsheet.elapsedTime)

  return (
    <>
      <Hero>
        <MainContainer>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={8} lg={6} className="py-5 pr-md-4 order-xs-2 order-md-1 order-xl-1">
                <h4 className="text-dark font-weight-bold pr-md-5">Monthly income from energy savings</h4>
                <p className="pr-md-5">
                  Invest in lucrative revenue streams generated by our energy management and air purification
                  systems and receive a monthly payment directly from Spere Financial.
                </p>
                <div className="mt-4 text-center text-md-left">
                  <Link to="/contact"><Button className="mr-2 mb-2" style={{width: "11em"}}>Contact Us</Button></Link>
                  <Link to="offering"><Button variant="outline-primary" className="mb-2" style={{width: "11em"}}>Current Offering</Button></Link>
                </div>
              </Col>
              <Col xs={12} md={4} lg={6} className="align-self-middle order-xs-1 order-md-2 text-center">
                <GraphTextUp dangerouslySetInnerHTML={{__html: moneyBoxTitle}}></GraphTextUp>
                <GraphTextCenter><span>{data.spreadsheet.moneySaved}<sup>*</sup></span></GraphTextCenter>
                <GraphTextDown dangerouslySetInnerHTML={{__html: elapsedTime}}></GraphTextDown>
                <GraphTextDisclaimer>*{data.spreadsheet.elapsedTimeDisclaimer}</GraphTextDisclaimer>
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </Hero>
    </>
  )
}

export default HeroIndex
